 // Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getFirestore } from "firebase/firestore";
 // TODO: Add SDKs for Firebase products that you want to use
 // https://firebase.google.com/docs/web/setup#available-libraries
 // Your web app's Firebase configuration
 const firebaseConfig = {
    apiKey: "AIzaSyAkYhAztMfc8Bsb2xacaFUFMpNb6jjb2mI",
    authDomain: "digger-beats-1eea4.firebaseapp.com",
    projectId: "digger-beats-1eea4",
    storageBucket: "digger-beats-1eea4.appspot.com",
    messagingSenderId: "942297007647",
    appId: "1:942297007647:web:e0580e6254e8c4eb09f72a"
  };
 // Initialize Firebase
 
 const app = initializeApp(firebaseConfig);
 // Export firestore database
 // It will be imported into your react app whenever it is needed
 export const db = getFirestore(app);