import ghost from './ghost.svg'
import search from './search.svg'

import Loading from './Loading'
import { getDoc, doc } from "firebase/firestore";
import {db} from './firebase';


import axios from 'axios'
import {useState, useEffect} from 'react'

function App() {

  const [textInput, setTextInput] = useState('')
  const [hasSubmitted, setHadSubmitted] = useState(false)
  const [imgLink, setImgLink] = useState('')
  const [songTitle, setSongTitle] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [prevCounter, setPrevCounter] = useState(0)
  const [iTag, setITag] = useState(0)
  const [YTUrl, setYTUrl] = useState('')
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [baseURL, setBaseURL] = useState('')


  useEffect(() => {
    const fetch = async () => {
      const docRef = doc(db, 'tunnelUrl/tunnelUrl')
      const tunnelUrl = await getDoc(docRef)
      setBaseURL(tunnelUrl.data()?.url)
    }

    fetch()

  }, [])

  const onEnterPress = async () => {
    await searchVideo();
  }

  const searchVideo = async () => {
    if(textInput?.length > 0 && !isLoading){
      setIsLoading(true)
    axios.post(`${baseURL}/search`, {
      search: textInput,
      counter: prevCounter
    }).then((response) => {
      setImgLink(response.data.imgLink)
      setSongTitle(response.data.title)
      setITag(response.data.itag)
      setYTUrl(response.data.url)
      setIsLoading(false)
      setHadSubmitted(true)
    })
  }
  }

  const handleDownload = async () => {
    if(!downloadLoading){
    axios.post(`${baseURL}/confirm`, {
      url: YTUrl,
      itag: iTag,
      title: songTitle
    }).then((response) => {

      setDownloadLoading(true)

      const handleFinish = () => {
        setDownloadLoading(false)
        setHadSubmitted(false)
      setTextInput('')
      setPrevCounter(0)
      }

      setTimeout(handleFinish, 3000)
    
    })
  }
  }

  const handleTryNext = async () => {
    setPrevCounter(prevCounter + 1)
    await searchVideo()
  }

  return (
    <div className="flex flex-col md:pt-12 pt-3 items-center h-screen w-screen bg-cream">
      {!hasSubmitted ? <>
      <img src={ghost} alt={''} className='animate-pulse'/>
      <div className='flex flex-col items-center md:mt-12 h-4/5 md:h-3/5  w-7/12 bg-gradient-to-r from-pblue to-blurple rounded-xl'>
        <div className='flex mt-8 text-[color:white] font-billo text-3xl md:text-5xl'>Digger Beats</div>
        <div className='flex items-center mt-4 bg-[color:white] w-11/12 h-20 rounded-md'>
          <img src={search} className='w-6 h-6 ml-4' alt={''}/>
          <input className='w-5/6 pl-2 pr-2 h-12 rounded-lg font-space focus:outline-none' placeholder='Enter Song Name Here' value={textInput} onKeyDown={(e) => {if(e.key === 'Enter'){onEnterPress();}}} onChange={(e) => {setTextInput(e.target.value)}}></input>
          <div className={`flex w-24 md:w-32 md:mr-2 md:h-12 h-full bg-purp font-space rounded-md justify-center items-center text-[color:white] ${downloadLoading ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'} hover:bg-darkpurp`} onClick={searchVideo}>{isLoading ?  <Loading isLoading={isLoading}/> :'Search'}</div>
        </div>
        <div className='flex flex-col md:mt-8 w-9/12'>
          <div className='text-[color:white] font-space text-lg font-bold self-center'>Instructions</div>
          <div className='md:mt-2 mt-4 text-[color:white] font-space text-xs md:text-base'>1. Type in song you want to download to play on the DJ Deck</div>
          <div className='md:mt-1 mt-3 text-[color:white] font-space text-xs md:text-base'>2. Search for the song</div>
          <div className='md:mt-1 mt-3 text-[color:white] font-space text-xs md:text-base'>3. If the song is found, press the download button </div>
          <div className='md:mt-1 mt-3 text-[color:white] font-space text-xs md:text-base'>4. If the song shown is incorrect, press the next option button to download next video in list </div>
          <div className='md:mt-1 mt-3 text-[color:white] font-space text-xs md:text-base'>5. Sometimes songs can take a while to download, so may not be ready instantly</div>
        </div>
      </div>
      </>
      : 
      <>
        <div className='flex flex-col items-center h-1/6 w-7/12 bg-gradient-to-r from-pblue to-blurple rounded-xl'>
        <div className='flex md:mt-8 mt-6 text-[color:white] font-billo text-3xl md:text-5xl'>Digger Beats</div>
        <img className='animate-bounce w-16 h-16' src={ghost} alt={''}/>
        <div className='flex items-center mt-2 bg-[color:white] w-11/12 h-28 shadow-lg rounded-lg'>
          <img src={search} className='w-6 h-6 ml-4' alt={''}/>
          <input className='w-5/6 pl-2 pr-2 h-16 rounded-lg font-space focus:outline-none' placeholder='Enter Song Name Here' value={textInput} onKeyDown={(e) => {if(e.key === 'Enter'){onEnterPress();}}} onChange={(e) => {setTextInput(e.target.value)}}></input>
          <div className={`flex w-32 mr-2 h-10 bg-purp font-space rounded-md justify-center items-center text-[color:white] ${downloadLoading ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'} s hover:bg-darkpurp`} onClick={searchVideo}>{isLoading ?  <Loading isLoading={isLoading}/> :'Search'}</div>
        </div>
        </div>
        <div className='flex flex-col mt-16 w-5/12'>
          <div className='font-space md:mt-4 md:text-lg text-sm font-bold self-center'>Instructions</div>
          <div className='mt-2 font-space text-xs md:text-base'>1. Type in song you want to download to play on the DJ Deck</div>
          <div className='font-space text-xs md:text-base'>2. Search for the song</div>
          <div className='font-space text-xs md:text-base'>3. If the song is found, press the download button </div>
          <div className='font-space text-xs md:text-base'>4. If the song shown is incorrect, press the next option button to download next video in list </div>
          <div className='font-space text-xs md:text-base'>5. Sometimes songs can take a while to download, so may not be ready instantly</div>
        </div>
        <div className='flex flex-col md:w-1/5 w-9/12 md:h-2/5 h-60 items-center mt-4 bg-[color:white] rounded-xl'>
          <img src={imgLink} alt='' className='mt-6 w-4/5 h-2/5 rounded-xl'/>
          <div className='font-space mt-4'>{songTitle}</div>
          <div className={`flex mt-4 w-11/12 h-12 bg-purp font-space rounded-md justify-center items-center text-[color:white] ${downloadLoading ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'} hover:bg-darkpurp`} onClick={handleDownload}>{downloadLoading ? <div className='font-space text-[color:white] animate-bounce'>Downloading..</div> : 'Download'}</div>
          <div className='flex mb-6 mt-2 w-11/12 h-12 bg-[color:white] border-2 border-purp font-space rounded-md justify-center items-center hover:cursor-pointer hover:bg-cream' onClick={handleTryNext}>{isLoading ? <Loading isLoading={isLoading} /> : 'GG Go Next'}</div>
        </div>
      </>
      }
    </div>
  );
}

export default App;

