import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Loading = ({ isLoading, style, size, children }) => {
  return isLoading ? (
    <div className={`justify-center items-center flex p-5 ${style}`}>
      <FontAwesomeIcon className={`animate-spin fill-current text-text-blue-200 dark:text-blue-400  opacity-60`} size={size} icon={faCircleNotch} />
    </div>
  ) : (
    <>{children}</>
  )
}

export default Loading
